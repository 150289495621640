import { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { IHeader } from 'components/Header';
import Header from 'components/Header/Header';
import AddIcon from '@mui/icons-material/Add';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { useNavigate } from 'react-router-dom';
import { ISampleDetailsProps } from 'pages/Sample/SampleDetails';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IReducer } from 'Reducers/IReducer';
import {
  getSampleById,
  assignSample,
  createJobAllotment,
  createTestRequest,
  createJobSheet,
  generateResult,
  assignMultipleSample,
  getResultTemplate,
  generateURLNumber,
  updateSample,
  updateResult,
} from 'actions/LaboratoryActions';
import ScreenLoader from 'components/ScreenLoader/ScreenLoader';
import useQuery from 'utils/getQueryParams';
import { getUserList } from 'actions/UserAction';
import moment from 'moment';
import { ISampleParameter } from 'exactt-types/lib';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { CustomFieldWithLabel } from 'components/CustomFieldWithLabel';

function _SampleDetails(props: ISampleDetailsProps) {
  const navigate = useNavigate();
  let query = useQuery();
  let sampleId = query.get('id');
  const laboratoryId = props.laboratory?.details?._id || '';

  const [loading, setLoading] = useState<boolean>(true);
  const [parameters, setParameters] = useState<ISampleParameter[] | []>([]);
  const [printModal, setPrintModal] = useState(false);
  const [printLabelModal, setPrintLabelModal] = useState(false);
  const [multiAssignDepartment, setMultiAssignDepartment] = useState<any>(undefined);
  const [multiAssignUser, setMultiAssignUser] = useState<any>(undefined);
  const [multiAssignAllotmentDate, setMultiAssignAllotmentDate] = useState<any>(moment().toDate());
  const [multiAssignDueDate, setMultiAssignDueDate] = useState<any>(moment().add(6, 'days').toDate());
  const [multiAssignLoading, setMultiAssignLoading] = useState(false);
  const [resultFormat, setResultFormat] = useState('');
  const [printWithNABL, setPrintWithNABL] = useState(props.laboratory?.details?.labType === 'PHARMA' ? 'NO' : 'YES');
  const [printWithStamp, setPrintWithStamp] = useState(props.laboratory?.details?.labType === 'PHARMA' ? 'NO' : 'YES');
  const [labelCount, setLabelCount] = useState(1);
  const [opinion, _setOpinion] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);

  const opinionRef = useRef(opinion);

  const setOpinion = (value: string) => {
    opinionRef.current = value;
    _setOpinion(value);
  };

  console.log('opinion -> ', opinion);

  const onFocus = async () => {
    if (sampleId) {
      await props.getSampleById(laboratoryId, sampleId);
      await props.getResultTemplate(laboratoryId);

      setLoading(false);
    }
  };

  const breadcrumbsList: IHeader['breadcrumbsList'] = [
    {
      label: 'Dashboard',
      onClick: () => navigate('/'),
    },
    {
      label: 'Samples',
      onClick: () => navigate('/laboratory/samples'),
    },
    {
      label: props.sampleDetails?.sampleId || '',
      onClick: () => navigate(`/laboratory/samples/sampleDetails?id=${sampleId}`),
    },
  ];

  const sampleDetails = props.sampleDetails;

  const PrintDetails = ({ label, value, width = 230 }: any) => (
    <Box display={'flex'}>
      <Typography lineHeight={1.5} fontSize={15} width={130}>
        {label}
      </Typography>
      <Typography lineHeight={1.5} fontSize={15} color={'GrayText'} width={width} pr={1}>
        {value}
      </Typography>
    </Box>
  );

  const handleAssignSample = async () => {
    try {
      let assignSampleBatch = parameters.map((i) =>
        props.assignSample(laboratoryId, i._id || '', {
          allotmentDate: i.allotmentDate || new Date(),
          dueDate: i.dueDate || new Date(),
          assignTo: i.assignTo || '',
        })
      );
      await Promise.all(assignSampleBatch);
    } catch (err) {
      console.log('err -> ', err);
    }
  };

  useEffect(() => {
    onFocus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setParameters(props.sampleDetails?.parameters?.map((i) => ({ ...i, assignTo: i.assignTo || '' })) || []);
    console.log('props.sampleDetails?.opinion -> ', props.sampleDetails?.opinion);
    setOpinion(props.sampleDetails?.opinion || '');
    opinionRef.current = props.sampleDetails?.opinion || '';
    // eslint-disable-next-line
  }, [props.sampleDetails]);

  // if (printLoading) return <FullScreenLoading />;

  const resultType = props.resultTemplate.map((r) => ({
    value: r._id,
    label: r.name,
  }));

  const labtype = props.laboratory?.details?.labType;

  return sampleDetails && !loading ? (
    <Box>
      <Header
        breadcrumbsList={breadcrumbsList}
        title={sampleDetails.sampleId || ''}
        right={
          props.laboratory?.access.role === 'ADMIN' || props.laboratory?.access.role === 'HOD' ? (
            <Box display={'flex'} justifyContent={'space-between'}>
              <Button
                variant={'text'}
                startIcon={<EditOutlined />}
                onClick={() => {
                  if (labtype === 'PHARMA') {
                    return navigate(`/laboratory/samples/persist-pharma-sample?id=${sampleId}`);
                  }
                  if (sampleDetails && sampleDetails.formType === 'FOOD') {
                    return navigate(`/laboratory/samples/KSZ?id=${sampleId}`);
                  }
                  if (sampleDetails && sampleDetails.formType === 'WATER') {
                    return navigate(`/laboratory/samples/persistSampleFood-Water?id=${sampleId}`);
                  }
                }}
              >
                Edit
              </Button>
              <Button
                variant={'text'}
                startIcon={<LocalPrintshopOutlinedIcon />}
                onClick={() => {
                  setPrintLabelModal(true);
                }}
              >
                Label
              </Button>
              <Button
                variant={'text'}
                startIcon={<LocalPrintshopOutlinedIcon />}
                onClick={() => {
                  // handleGenerateResult();
                  setPrintModal(true);
                  // window.open(
                  //   `${window.location.origin}/laboratory/samples/print/resultV2?id=${sampleId}`,
                  //   'Print',
                  //   'width=1000,height=700'
                  // );
                }}
              >
                Generate Result
              </Button>
              <Button
                variant={'text'}
                startIcon={<LocalPrintshopOutlinedIcon />}
                onClick={async () => {
                  // handleCreateJobAllotment();
                  window.open(
                    `${window.location.origin}/laboratory/samples/print/jobAllotment?id=${sampleId}`,
                    'Print',
                    'width=1000,height=700'
                  );
                }}
              >
                Job Allotment
              </Button>
              <Button
                variant={'text'}
                startIcon={<LocalPrintshopOutlinedIcon />}
                onClick={async () => {
                  // handleCreateTestRequest();
                  window.open(
                    `${window.location.origin}/laboratory/samples/print/testReq?id=${sampleId}`,
                    'Print',
                    'width=1000,height=700'
                  );
                }}
              >
                Test Request
              </Button>
              <Button
                variant={'text'}
                startIcon={<LocalPrintshopOutlinedIcon />}
                onClick={async () => {
                  // handleCreateJobSheet();
                  window.open(
                    `${window.location.origin}/laboratory/samples/print/${
                      props.laboratory?.details?.type === 'PHARMA' ? 'jobSheetPharma' : 'jobSheet'
                    }?id=${sampleId}`,
                    'Print',
                    'width=1000,height=700'
                  );
                }}
              >
                Job Sheet
              </Button>
              <Button
                variant={'text'}
                startIcon={<AddIcon />}
                onClick={() => navigate(`/laboratory/samples/result?id=${sampleId}`)}
              >
                Results
              </Button>
            </Box>
          ) : (
            <Button
              variant={'text'}
              startIcon={<AddIcon />}
              onClick={() => navigate(`/laboratory/samples/result?id=${sampleId}`)}
            >
              Results
            </Button>
          )
        }
      />
      <Box p={2} pt={10}>
        <Box display={'flex'} justifyContent={'space-between'} mt={1} mb={1}>
          <Box>
            <Typography variant="h6" fontSize={15} lineHeight={1}>
              {sampleDetails.name}
            </Typography>
            <Typography variant="subtitle1" fontSize={14}>
              {sampleDetails.genericName}
            </Typography>
          </Box>
          <Box>
            {props.sampleDetails?.status === 'PENDING' && (
              <Chip label={'Pending'} variant={'outlined'} color={'error'} />
            )}
            {props.sampleDetails?.status === 'INPROGRESS' && (
              <Chip label={'In process'} variant={'outlined'} color={'info'} />
            )}
            {props.sampleDetails?.status === 'COMPLETED' && (
              <Chip label={'Complete'} variant={'outlined'} color={'success'} />
            )}
          </Box>
        </Box>
        <Divider />
        {typeof sampleDetails.customer === 'object' && props.laboratory?.access?.role === 'ADMIN' && (
          <Box mt={2}>
            <Typography
              variant="h6"
              fontSize={12}
              lineHeight={1}
              style={{ textTransform: 'uppercase' }}
              color={'GrayText'}
            >
              Customer
            </Typography>
            <Box mb={1}>
              <Typography variant="h6" fontSize={15} lineHeight={1.3} mt={1}>
                {sampleDetails.customer.name}
              </Typography>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box>
                  <Typography variant="subtitle1" fontSize={14} lineHeight={1.3}>
                    {sampleDetails.customer.contactDetails?.[0]?.name}
                  </Typography>
                  <Typography variant="subtitle1" fontSize={14} lineHeight={1.3}>
                    {sampleDetails.customer.country} {sampleDetails.customer.phone}
                  </Typography>
                  <Typography variant="subtitle1" fontSize={14} lineHeight={1.3}>
                    {sampleDetails.customer.email}
                  </Typography>
                </Box>
                <Box style={{ textAlign: 'right' }}>
                  <Typography variant="subtitle1" fontSize={14} lineHeight={1.3}>
                    {sampleDetails?.customer?.address?.[0]?.line1}
                  </Typography>
                  <Typography variant="subtitle1" fontSize={14} lineHeight={1.3}>
                    {sampleDetails?.customer?.address?.[0]?.line2}
                  </Typography>
                  <Typography variant="subtitle1" fontSize={14} lineHeight={1.3}>
                    {sampleDetails.customer.address?.[0]?.city}, {sampleDetails.customer.address?.[0]?.state} -{' '}
                    {sampleDetails.customer.address?.[0]?.zip}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
          </Box>
        )}

        {typeof sampleDetails.hod === 'object' && typeof sampleDetails.testGroup === 'object' && (
          <Box mt={2}>
            <Typography
              variant="h6"
              fontSize={12}
              lineHeight={1}
              style={{ textTransform: 'uppercase' }}
              color={'GrayText'}
            >
              Sample Details
            </Typography>
            <Box display={'flex'} mb={2} mt={2}>
              <Box>
                <PrintDetails
                  label={'Test Group'}
                  value={`${sampleDetails.testGroup.name} (${sampleDetails.testGroup.prefix})`}
                />
                <PrintDetails
                  label={'Collection Date'}
                  value={`${moment(sampleDetails.collectionDate).format('DD/MM/YYYY')}`}
                />
                <PrintDetails label={'Sample Code'} value={`${sampleDetails.sampleCode}`} />
                <PrintDetails label={'Marking'} value={`${sampleDetails.marking}`} />
                <PrintDetails label={'Batch size'} value={`${sampleDetails.batchSize}`} />
                <PrintDetails label={'Sample Packing'} value={`${sampleDetails.samplePacking}`} />
                <PrintDetails label={'Serving Size'} value={`${sampleDetails.servingSize}`} />
                <PrintDetails label={'Description'} value={`${sampleDetails.description}`} />
              </Box>
              <Box>
                <PrintDetails label={'Due Date'} value={`${moment(sampleDetails.dueDate).format('DD/MM/YYYY')}`} />
                <PrintDetails label={'Mfg. Date'} value={`${moment(sampleDetails.mfgDate).format('DD/MM/YYYY')}`} />
                <PrintDetails label={'Brand Name'} value={`${sampleDetails.brandName}`} />
                <PrintDetails label={'Supplier/Importer'} value={`${sampleDetails.supplier}`} />
                <PrintDetails label={'Drug/Lic No.'} value={`${sampleDetails.drugLicNo}`} />
                <PrintDetails label={'Sample Qty.'} value={`${sampleDetails.sampleQty}`} />
                <PrintDetails label={'Env Condition'} value={`${sampleDetails.envCondition}`} />
              </Box>
              <Box>
                <PrintDetails
                  label={'Lab Due Date'}
                  value={`${moment(sampleDetails.labDueDate).format('DD/MM/YYYY')}`}
                />
                <PrintDetails label={'Exp Date'} value={`${moment(sampleDetails.expDate).format('DD/MM/YYYY')}`} />
                <PrintDetails label={'Manufacture'} value={`${sampleDetails.manufacture}`} />
                <PrintDetails label={'Batch/Lot No.'} value={`${sampleDetails.batchNo}`} />
                <PrintDetails label={'Type'} value={`${sampleDetails.type}`} />
                <PrintDetails label={'Sample Condition'} value={`${sampleDetails.sampleCondition}`} />
                <PrintDetails
                  label={'Collected By'}
                  value={`${typeof sampleDetails.collectionBy === 'string' ? sampleDetails.collectionBy : ''}`}
                />
              </Box>
            </Box>
            <Divider />
          </Box>
        )}

        {sampleDetails.additionalFields ? (
          <Box mt={2}>
            <Typography
              variant="h6"
              fontSize={12}
              lineHeight={1}
              style={{ textTransform: 'uppercase' }}
              color={'GrayText'}
            >
              Additional Details
            </Typography>
            <Box display={'flex'} mb={2} mt={2} flexWrap={'wrap'}>
              {Object.keys(sampleDetails.additionalFields).map((key) => (
                <Box>
                  <PrintDetails label={key} value={sampleDetails.additionalFields[key]} />
                </Box>
              ))}
            </Box>
            <Divider />
          </Box>
        ) : null}

        <Box mt={2}>
          <Typography
            variant="h6"
            fontSize={12}
            lineHeight={1}
            style={{ textTransform: 'uppercase' }}
            color={'GrayText'}
          >
            Testing Details
          </Typography>
          <Box display={'flex'} mb={2} mt={2}>
            <PrintDetails label={'Sampling Method'} value={sampleDetails.samplingMethod} />
          </Box>
          {props.laboratory?.access.role === 'ADMIN' || props.laboratory?.access.role === 'HOD' ? (
            <>
              <Divider />
              <Box mt={2}>
                <Typography
                  variant="h6"
                  fontSize={12}
                  lineHeight={1}
                  style={{ textTransform: 'uppercase' }}
                  color={'GrayText'}
                >
                  Parameters
                </Typography>
                <Box p={2}>
                  <Typography
                    variant="h6"
                    fontSize={14}
                    lineHeight={1}
                    style={{ textTransform: 'uppercase' }}
                    color={'GrayText'}
                  >
                    ASSIGN MULTIPLE PARAMETERS
                  </Typography>
                  {!multiAssignLoading && (
                    <Grid container mb={1} spacing={1}>
                      <Grid item xs={3} pl={1}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="department-select-label">Department</InputLabel>
                          <Select
                            fullWidth
                            label="Department"
                            labelId="department-select-label"
                            id="department-select-helper"
                            name="department"
                            placeholder="Select department"
                            value={multiAssignDepartment}
                            size="small"
                            onChange={(event) => {
                              setMultiAssignDepartment(event.target.value);
                            }}
                          >
                            {props.laboratory?.departments?.map((item) => (
                              <MenuItem value={item._id}>{item.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3} pl={1}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="user-select-label">User</InputLabel>
                          <Select
                            label="User"
                            fullWidth
                            labelId="user-select-label"
                            id="user-select-helper"
                            name="user"
                            placeholder="Select user"
                            size="small"
                            value={multiAssignUser}
                            onChange={(event) => {
                              setMultiAssignUser(event.target.value);
                            }}
                          >
                            {props.userList.map((item) => (
                              <MenuItem value={item.user._id} key={item._id}>
                                {item.user.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} pl={1}>
                        <FormControl fullWidth size="small">
                          {/* <InputLabel id="department-select-label">Allotment date</InputLabel> */}
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <MobileDatePicker
                              inputFormat={'DD/MM/YYYY'}
                              label="Allotment date"
                              onChange={(value) => {
                                setMultiAssignAllotmentDate(moment(value).toDate());
                              }}
                              value={multiAssignAllotmentDate}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select date" fullWidth margin={'none'} />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} pl={1}>
                        <FormControl fullWidth size="small">
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <MobileDatePicker
                              inputFormat={'DD/MM/YYYY'}
                              label="Due date"
                              onChange={(value) => {
                                setMultiAssignDueDate(moment(value).toDate());
                              }}
                              value={multiAssignDueDate}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select date" fullWidth margin={'none'} />
                              )}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      </Grid>
                      <Grid item xs={2}>
                        <Box mt={1}>
                          <Button
                            variant={'contained'}
                            disabled={multiAssignLoading || !multiAssignDepartment || !multiAssignUser}
                            onClick={async () => {
                              setMultiAssignLoading(true);
                              await props.assignMultipleSample(laboratoryId, {
                                sampleId: sampleId || '',
                                department: multiAssignDepartment,
                                user: multiAssignUser,
                                allotmentDate: multiAssignAllotmentDate,
                                dueDate: multiAssignDueDate,
                              });

                              setMultiAssignLoading(false);
                              setMultiAssignDepartment('');
                              setMultiAssignUser('');
                              setMultiAssignAllotmentDate(moment().toDate());
                              setMultiAssignDueDate(moment().add(6, 'days').toDate());
                            }}
                          >
                            Assign
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}

                  <Box>
                    <Grid container flex={1} height={45} display={'flex'}>
                      <Grid className="table-grid center-text" item xs={3}>
                        <Typography
                          variant="h6"
                          fontSize={12}
                          fontWeight={'bold'}
                          lineHeight={1}
                          style={{ textTransform: 'uppercase' }}
                          color={'GrayText'}
                        >
                          Parameter
                        </Typography>
                      </Grid>
                      <Grid className="table-grid center-text" item xs={2}>
                        <Typography
                          variant="h6"
                          fontSize={12}
                          fontWeight={'bold'}
                          lineHeight={1}
                          style={{ textTransform: 'uppercase' }}
                          color={'GrayText'}
                        >
                          Department
                        </Typography>
                      </Grid>
                      <Grid className="table-grid center-text" item xs={3}>
                        <Typography
                          variant="h6"
                          fontSize={12}
                          fontWeight={'bold'}
                          lineHeight={1}
                          style={{ textTransform: 'uppercase' }}
                          color={'GrayText'}
                        >
                          Assign to
                        </Typography>
                      </Grid>
                      <Grid className="table-grid center-text" item xs={2}>
                        <Typography
                          variant="h6"
                          fontSize={12}
                          fontWeight={'bold'}
                          lineHeight={1}
                          style={{ textTransform: 'uppercase' }}
                          color={'GrayText'}
                        >
                          Allotment date
                        </Typography>
                      </Grid>
                      <Grid className="table-grid center-text" item xs={2}>
                        <Typography
                          variant="h6"
                          fontSize={12}
                          fontWeight={'bold'}
                          lineHeight={1}
                          style={{ textTransform: 'uppercase' }}
                          color={'GrayText'}
                        >
                          Due date
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  {!multiAssignLoading &&
                    parameters?.map((p) => (
                      <Grid container>
                        <Grid
                          className="table-grid"
                          item
                          xs={3}
                          display={'flex'}
                          alignItems={'center'}
                          style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
                        >
                          <Typography variant="h6" fontSize={15} lineHeight={1}>
                            {p.name}
                          </Typography>
                        </Grid>
                        <Grid className="table-grid" item xs={2} display={'flex'} alignItems={'center'} pl={1}>
                          <Typography
                            style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}
                            variant="h6"
                            fontSize={15}
                            lineHeight={1}
                          >
                            {/* @ts-ignore: Unreachable code error */}
                            {p.department?.name}
                          </Typography>
                        </Grid>
                        <Grid className="table-grid" item xs={3} pl={1}>
                          <select
                            id="assignTo-select-helper"
                            name="assignTo"
                            style={{
                              width: '100%',
                              fontSize: 14,
                              border: 'none',
                              background: 'none',
                              height: '100%',
                              textAlign: 'center',
                            }}
                            placeholder="Select user"
                            value={(p.assignTo as any)?._id}
                            onChange={(e) => {
                              let updateItem = parameters.map((i) =>
                                i._id === p._id ? { ...i, assignTo: e.target.value } : i
                              );
                              setParameters(updateItem);
                            }}
                          >
                            <option value={''} key={'01'}></option>
                            {props.userList.map((item) => (
                              <option value={item.user._id} key={item._id}>
                                {item.user.name}
                              </option>
                            ))}
                          </select>
                        </Grid>
                        <Grid className="table-grid" item xs={2} pl={1}>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <MobileDatePicker
                              inputFormat={p.allotmentDate ? 'DD/MM/YYYY' : ' '}
                              onChange={(value) => {
                                let updateItem = parameters.map((i) =>
                                  i._id === p._id ? { ...i, allotmentDate: moment(value).toDate() } : i
                                );
                                setParameters(updateItem);
                              }}
                              value={p.allotmentDate}
                              renderInput={(params) => (
                                // @ts-ignore: next-line
                                <InputBase {...params} placeholder="Select date" fullWidth required margin={'none'} />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid className="table-grid" item xs={2} pl={1}>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <MobileDatePicker
                              inputFormat={p.dueDate ? 'DD/MM/YYYY' : ' '}
                              onChange={(value) => {
                                let updateItem = parameters.map((i) =>
                                  i._id === p._id ? { ...i, dueDate: moment(value).toDate() } : i
                                );
                                setParameters(updateItem);
                              }}
                              value={p.dueDate}
                              renderInput={(params) => (
                                // @ts-ignore: next-line
                                <InputBase {...params} placeholder="Select date" />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    ))}
                  <Divider sx={{ marginTop: 1 }} />
                </Box>
              </Box>
            </>
          ) : null}
        </Box>
      </Box>

      <Dialog open={printModal} onClose={() => setPrintModal(false)} maxWidth={'sm'} fullWidth>
        <DialogTitle>Print</DialogTitle>
        <DialogContent>
          <CustomFieldWithLabel label="Print Format">
            <Select
              labelId="print-select-label"
              id="Print-Template"
              fullWidth
              size="small"
              value={resultFormat}
              onChange={(e) => {
                setResultFormat(e.target.value as any);
              }}
            >
              {resultType.map((item) => (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </CustomFieldWithLabel>
          {props.laboratory?.details?.labType !== 'PHARMA' ? (
            <>
              <Box mt={1} />
              <CustomFieldWithLabel label="NABL">
                <Select
                  labelId="authorizedSignature2-select-label"
                  id="authorizedSignature2-select-helper"
                  name="authorizedSignature2"
                  onChange={(e) => {
                    setPrintWithNABL(e.target.value as any);
                  }}
                  size="small"
                  fullWidth
                  value={printWithNABL}
                >
                  <MenuItem value={'YES'}>YES</MenuItem>
                  <MenuItem value={'NO'}>NO</MenuItem>
                </Select>
              </CustomFieldWithLabel>
              <Box mt={1} />
              <CustomFieldWithLabel label="With Stamp">
                <Select
                  labelId="stamp-select-label"
                  id="stamp-select-helper"
                  name="stamp"
                  onChange={(e) => {
                    setPrintWithStamp(e.target.value as any);
                  }}
                  size="small"
                  fullWidth
                  value={printWithStamp}
                >
                  <MenuItem value={'YES'}>YES</MenuItem>
                  <MenuItem value={'NO'}>NO</MenuItem>
                </Select>
              </CustomFieldWithLabel>
            </>
          ) : null}

          <Box mb={1}>
            <TextField
              multiline
              value={opinion}
              onChange={(e) => {
                setOpinion(e.target.value);
              }}
              fullWidth
              minRows={2}
            />
            <Button
              variant="contained"
              size="small"
              disabled={updateLoading}
              onClick={async () => {
                setUpdateLoading(true);
                await props.updateResult(
                  laboratoryId,
                  {
                    analysisDate: sampleDetails.analysisDate,
                    completeDate: sampleDetails.completeDate,
                    reportDate: sampleDetails.reportDate,
                    ulr: sampleDetails.ulr,
                    opinion: opinionRef.current,
                  } as any,
                  sampleId || ''
                );
                setUpdateLoading(false);
              }}
            >
              {updateLoading ? 'Please wait' : 'Save Opinion'}
            </Button>
          </Box>

          {printWithNABL === 'YES' &&
            props.laboratory?.details?.type !== 'PHARMA' &&
            (sampleDetails.ulr ? (
              <CustomFieldWithLabel label="ULR">
                <TextField disabled fullWidth margin={'none'} value={sampleDetails.ulr} />
              </CustomFieldWithLabel>
            ) : (
              <Button
                onClick={async () => {
                  await props.generateURLNumber(laboratoryId, sampleId || '');
                  await props.getSampleById(laboratoryId, sampleId || '');
                }}
              >
                Generate ULR
              </Button>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPrintModal(false)}>Cancel</Button>
          <Button
            variant="contained"
            disabled={!printWithNABL || !resultFormat || updateLoading}
            onClick={() => {
              window.open(
                `${window.location.origin}/laboratory/samples/print/resultV2?id=${sampleId}&resultFormat=${resultFormat}&printWithNABL=${printWithNABL}&stamp=${printWithStamp}`,
                'Print',
                'width=1000,height=700'
              );
              setPrintModal(false);
            }}
          >
            Preview & Print
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={printLabelModal} onClose={() => setPrintLabelModal(false)} maxWidth={'sm'} fullWidth>
        <DialogTitle>Print</DialogTitle>
        <DialogContent>
          <CustomFieldWithLabel label="Nos of Label">
            <TextField
              type="number"
              onChange={(e) => {
                setLabelCount(Number(e.target.value));
              }}
              value={labelCount}
            />
          </CustomFieldWithLabel>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setPrintLabelModal(false)}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              window.open(
                `${window.location.origin}/laboratory/samples/print/sampleLabel?sampleId=${sampleDetails.sampleId}&labelCount=${labelCount}`,
                'Print',
                'width=1000,height=700'
              );
              setPrintLabelModal(false);
            }}
          >
            Preview & Print
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  ) : (
    <ScreenLoader />
  );
}

const mapStateToProps = (state: IReducer) => ({
  laboratory: state.laboratory.current,
  userList: state.laboratory.users,
  sampleDetails: state.laboratory.sampleDetails,
  resultTemplate: state.laboratory.resultTemplate,
});

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators(
      {
        getSampleById,
        getUserList,
        assignSample,
        createJobAllotment,
        createTestRequest,
        createJobSheet,
        generateResult,
        assignMultipleSample,
        getResultTemplate,
        generateURLNumber,
        updateResult,
      },
      dispatch
    ),
  };
}

export const SampleDetails = connect(mapStateToProps, mapDispatchToProps)(_SampleDetails);
